<!--
* @description Report3D
* @fileName Report3D.vue
* @date 2024/07/04 16:33:22
-->
<template>
  <div class="Report3D">
    <div class="z_banner">
      <img class="z_b_img" :src="$t(`Report3D.banner`)" alt="banner" />
    </div>
    <div class="ptjs">
      <div class="p_con">
        <div class="p_descript font-size-18 font-weight-400 widthCon">
          {{ $t("Report3D.section1_content") }}
          <div class="indentation">{{ $t("Report3D.section2_content") }}</div>
        </div>

        <!-- 视频 -->
        <div class="video widthCon">
          <video
            controls
            :src="this.$videBaseUrlNew + $t(`Report3D.${videoUrl[0].url}`)"
            style="width: 100%; height: 100%"
          ></video>
        </div>

        <div class="flex-row-between marginTop80 widthCon">
          <div>
            <img
              :src="$t(`Report3D.${imgs[0].url}`)"
              alt=""
              style="width: 100%; max-width: 1920px"
            />
            <div
              class="imgAndText text-align-center font-size-24 font-weight-600"
            >
              {{ $t("Report3D.img_title1") }}
            </div>
          </div>
          <div>
            <img
              :src="$t(`Report3D.${imgs[1].url}`)"
              alt=""
              style="width: 100%; max-width: 1920px"
            />
            <div
              class="imgAndText text-align-center font-size-24 font-weight-600"
            >
              {{ $t("Report3D.img_title2") }}
            </div>
          </div>
        </div>

        <div
          :style="{
            backgroundImage: 'url(' + $t(`Report3D.${coverImg[0].url}`) + ')',
            width: '100%',
            height: `${this.$i18n.locale === '简体中文' ? '1048px' : '1176px'}`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
          }"
        >
          <div class="widthCon">
            <div
              class="paddingTop80 titleBottom text-align-center font-size-36 font-weight-600 font-color-black-1D1D1F"
            >
              {{ $t("Report3D.section3_title") }}
            </div>
            <div class="p_descript font-size-18 font-weight-400">
              {{ $t("Report3D.section3_content") }}
              <div class="indentation">
                {{ $t("Report3D.section3_content2") }}
              </div>
            </div>
            <div class="flex-row-between flex-wrap">
              <img
                class="imgBottom"
                :src="$t(`Report3D.${imgs[2].url}`)"
                alt=""
                style="width: 49%; max-width: 1920px"
              />
              <img
                class="imgBottom"
                :src="$t(`Report3D.${imgs[3].url}`)"
                alt=""
                style="width: 49%; max-width: 1920px"
              />
              <img
                class="imgBottom"
                :src="$t(`Report3D.${imgs[4].url}`)"
                alt=""
                style="width: 49%; max-width: 1920px"
              />
              <img
                class="imgBottom"
                :src="$t(`Report3D.${imgs[5].url}`)"
                alt=""
                style="width: 49%; max-width: 1920px"
              />
            </div>
          </div>
        </div>

        <div
          class="widthCon marginTop80 titleBottom text-align-center font-size-36 font-weight-600 font-color-black-1D1D1F"
        >
          {{ $t("Report3D.section4_title") }}
        </div>
        <div class="p_descript font-size-18 font-weight-400 widthCon">
          {{ $t("Report3D.section4_content") }}
        </div>
        <div class="flex-row-between flex-wrap widthCon">
          <img
            class="imgBottom"
            :src="$t(`Report3D.${imgs[6].url}`)"
            alt=""
            style="width: 49%; max-width: 1920px"
          />
          <img
            class="imgBottom"
            :src="$t(`Report3D.${imgs[7].url}`)"
            alt=""
            style="width: 49%; max-width: 1920px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Report3D",
  components: {},
  data() {
    return {
      imgs: [
        { url: "section1_img1" },
        { url: "section1_img2" },
        { url: "section1_img3" },
        { url: "section1_img4" },
        { url: "section1_img5" },
        { url: "section1_img6" },
        { url: "section1_img7" },
        { url: "section1_img8" },
      ],
      videoUrl: [{ url: "videoUrl" }],
      coverImg: [{ url: "cover1" }],
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.Report3D {
  padding-bottom: 171px;
  background-color: #fff;
  .z_b_img {
    width: 100%;
    height: 500px;
  }
  .ptjs {
    // height: 1010px;
    margin-top: -3px;
    background-color: #fff;
    .widthCon {
      width: 1200px;
      margin: auto;
    }
    .p_con {
      padding-top: 64px;
      .video {
        height: 675px;
        margin-top: 32px;
      }
      .marginTop80 {
        margin-top: 80px;
      }
      .paddingTop80 {
        padding-top: 80px;
      }
      .imgAndText {
        margin: 27px 0 40px 0;
      }
      .imgBottom {
        margin-bottom: 32px;
      }
      .titleBottom {
        margin-bottom: 32px;
      }

      .p_descript {
        margin-top: 18px;
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 2px;
        margin-bottom: 32px;
        text-indent: 36px;
        text-align: justify;
        color: rgba(29, 29, 31, 0.8);
      }
    }
  }
}
</style>
