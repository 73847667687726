var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Report3D"},[_c('div',{staticClass:"z_banner"},[_c('img',{staticClass:"z_b_img",attrs:{"src":_vm.$t("Report3D.banner"),"alt":"banner"}})]),_c('div',{staticClass:"ptjs"},[_c('div',{staticClass:"p_con"},[_c('div',{staticClass:"p_descript font-size-18 font-weight-400 widthCon"},[_vm._v(" "+_vm._s(_vm.$t("Report3D.section1_content"))+" "),_c('div',{staticClass:"indentation"},[_vm._v(_vm._s(_vm.$t("Report3D.section2_content")))])]),_c('div',{staticClass:"video widthCon"},[_c('video',{staticStyle:{"width":"100%","height":"100%"},attrs:{"controls":"","src":this.$videBaseUrlNew + _vm.$t(("Report3D." + (_vm.videoUrl[0].url)))}})]),_c('div',{staticClass:"flex-row-between marginTop80 widthCon"},[_c('div',[_c('img',{staticStyle:{"width":"100%","max-width":"1920px"},attrs:{"src":_vm.$t(("Report3D." + (_vm.imgs[0].url))),"alt":""}}),_c('div',{staticClass:"imgAndText text-align-center font-size-24 font-weight-600"},[_vm._v(" "+_vm._s(_vm.$t("Report3D.img_title1"))+" ")])]),_c('div',[_c('img',{staticStyle:{"width":"100%","max-width":"1920px"},attrs:{"src":_vm.$t(("Report3D." + (_vm.imgs[1].url))),"alt":""}}),_c('div',{staticClass:"imgAndText text-align-center font-size-24 font-weight-600"},[_vm._v(" "+_vm._s(_vm.$t("Report3D.img_title2"))+" ")])])]),_c('div',{style:({
          backgroundImage: 'url(' + _vm.$t(("Report3D." + (_vm.coverImg[0].url))) + ')',
          width: '100%',
          height: ("" + (this.$i18n.locale === '简体中文' ? '1048px' : '1176px')),
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
        })},[_c('div',{staticClass:"widthCon"},[_c('div',{staticClass:"paddingTop80 titleBottom text-align-center font-size-36 font-weight-600 font-color-black-1D1D1F"},[_vm._v(" "+_vm._s(_vm.$t("Report3D.section3_title"))+" ")]),_c('div',{staticClass:"p_descript font-size-18 font-weight-400"},[_vm._v(" "+_vm._s(_vm.$t("Report3D.section3_content"))+" "),_c('div',{staticClass:"indentation"},[_vm._v(" "+_vm._s(_vm.$t("Report3D.section3_content2"))+" ")])]),_c('div',{staticClass:"flex-row-between flex-wrap"},[_c('img',{staticClass:"imgBottom",staticStyle:{"width":"49%","max-width":"1920px"},attrs:{"src":_vm.$t(("Report3D." + (_vm.imgs[2].url))),"alt":""}}),_c('img',{staticClass:"imgBottom",staticStyle:{"width":"49%","max-width":"1920px"},attrs:{"src":_vm.$t(("Report3D." + (_vm.imgs[3].url))),"alt":""}}),_c('img',{staticClass:"imgBottom",staticStyle:{"width":"49%","max-width":"1920px"},attrs:{"src":_vm.$t(("Report3D." + (_vm.imgs[4].url))),"alt":""}}),_c('img',{staticClass:"imgBottom",staticStyle:{"width":"49%","max-width":"1920px"},attrs:{"src":_vm.$t(("Report3D." + (_vm.imgs[5].url))),"alt":""}})])])]),_c('div',{staticClass:"widthCon marginTop80 titleBottom text-align-center font-size-36 font-weight-600 font-color-black-1D1D1F"},[_vm._v(" "+_vm._s(_vm.$t("Report3D.section4_title"))+" ")]),_c('div',{staticClass:"p_descript font-size-18 font-weight-400 widthCon"},[_vm._v(" "+_vm._s(_vm.$t("Report3D.section4_content"))+" ")]),_c('div',{staticClass:"flex-row-between flex-wrap widthCon"},[_c('img',{staticClass:"imgBottom",staticStyle:{"width":"49%","max-width":"1920px"},attrs:{"src":_vm.$t(("Report3D." + (_vm.imgs[6].url))),"alt":""}}),_c('img',{staticClass:"imgBottom",staticStyle:{"width":"49%","max-width":"1920px"},attrs:{"src":_vm.$t(("Report3D." + (_vm.imgs[7].url))),"alt":""}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }